import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useConnectWallet} from '@web3-onboard/react'
import {AppDispatch} from '../../store/store'
import {
    getSigner,
    getWalletAddress,
    setCurrentNetwork,
    setSigner,
    setWalletAddress,
    walletChanged
} from '../../store/appSlice'
import {setToStorage} from '../../store/storage'

const Authorization = () => {
    const signer = useSelector(getSigner)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()
    const [{wallet}] = useConnectWallet()

    useEffect(() => {
        if (wallet) {
            setToStorage('connectedWallet', wallet.label)
            if (walletAddress !== wallet.accounts[0].address) {
                dispatch(setWalletAddress(wallet.accounts[0].address))
                if (walletAddress) {
                    dispatch(walletChanged())
                }
            }
            dispatch(setCurrentNetwork(wallet.chains[0].id))
/*
            const ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
            const signer = ethersProvider.getSigner()
            dispatch(setSigner(signer))
*/
        }
    }, [wallet])

    return <></>
}

export default Authorization
