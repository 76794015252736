import {configureStore} from '@reduxjs/toolkit'
import appReducer from './appSlice'
import ensReducer from './ensSlice'

const store = configureStore({
    reducer: {
        app: appReducer,
        ens: ensReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
