import React from 'react'
import {NavLink, Outlet} from 'react-router-dom'
import {
    AppBar,
    Container,
    createTheme,
    CssBaseline,
    GlobalStyles, Grid,
    Link,
    ThemeProvider,
    Toolbar,
    Typography
} from '@mui/material'
import {WalletBlock} from '../wallet'
import {Authorization} from '../auth'

const footers: { title: string, links: { href: string, name: string, blank?: boolean }[] }[] = [
    {
        title: 'Buy domains',
        links: [
            {name: 'Buy .ETH, .BNB, .ARB name', href: 'https://space.id/?inviter=menaskop.bnb', blank: true},
            {name: 'BUY .NFT, .WALLET, .BITCOIN, etc.', href: 'https://unstoppabledomains.com/?ref=da9bc9eafa9d489', blank: true},
            {name: 'Buy .BIT, .COIN, .TOR, etc.', href: 'https://peername.com/#5469', blank: true},
            {name: 'W3DNA', href: 'https://w3dna.net?referrer=32737', blank: true},
            {name: 'Buy Freename', href: 'https://freename.io?ref=slimy-pants-build&utm_source=clipboard', blank: true},
        ],
    },
]
const theme = createTheme({
    palette: {
        mode: 'dark',
    },
})

const DefaultLayout = () => {
    return <ThemeProvider theme={theme}>
        <Authorization/>
        <GlobalStyles styles={{ul: {margin: 0, padding: 0, listStyle: 'none'}}}/>
        <CssBaseline/>
        <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={{borderBottom: (theme) => `1px solid ${theme.palette.divider}`}}
        >
            <Toolbar sx={{flexWrap: 'wrap'}}>
                <Typography
                    component={NavLink}
                    to={'/'}
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{flexGrow: 1, textDecoration: 'none'}}
                >
                    Web 3.0 names
                </Typography>
                <nav>
                    <Link
                        variant="button"
                        color="text.primary"
                        sx={{my: 1, mx: 1.5}}
                        component={NavLink}
                        to={'/about'}
                    >
                        About
                    </Link>
                </nav>
                <WalletBlock/>
            </Toolbar>
        </AppBar>
        <Outlet/>
        <Container
            maxWidth="md"
            component="footer"
            sx={{
                borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                mt: 8,
                py: [3, 6],
            }}
        >
            <Grid container spacing={4} justifyContent="space-evenly">
                {footers.map((footer) => (
                    <Grid item xs={6} sm={5} key={footer.title}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            {footer.title}
                        </Typography>
                        <ul>
                            {footer.links.map((item, index) => (
                                <li key={index}>
                                    {item.blank ?
                                        <Link
                                            href={item.href}
                                            target={'_blank'}
                                            variant="subtitle1"
                                            color="text.secondary"
                                        >
                                            {item.name}
                                        </Link>
                                        :
                                        <Link
                                            component={NavLink}
                                            to={item.href}
                                            variant="subtitle1"
                                            color="text.secondary"
                                        >
                                            {item.name}
                                        </Link>
                                    }
                                </li>
                            ))}
                        </ul>
                    </Grid>
                ))}
            </Grid>
            <Typography variant="body2" color="text.secondary" align="center" sx={{mt: 5}}>
                With our app you can find the most interesting Web3 names. The service is developed by losos.eth &
                menaskop.eth
            </Typography>
        </Container>
    </ThemeProvider>
}

export default DefaultLayout
