import React from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import {Web3OnboardProvider, init} from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
import {AccountCenterOptions} from '@web3-onboard/core/dist/types'
import App from './App'
import store from './store/store'
import reportWebVitals from './reportWebVitals'
import {APP_URL, WALLETCONNECT_ID} from './utils/constants'
import {getChainsForOnboard} from './utils/functions'

import app_icon from './static/img/logo512.png'

const injected = injectedModule()
const walletConnect = walletConnectModule({
    projectId: WALLETCONNECT_ID,
    dappUrl: APP_URL,
})

const wallets = [
    injected,
    walletConnect,
]
const appMetadata = {
    name: 'cryptodomain.io app',
    icon: app_icon,
    description: 'The first free search for Web3 names from various providers. Best decentralized domains',
    recommendedInjectedWallets: [
        {name: 'MetaMask', url: 'https://metamask.io'},
        {name: 'Coinbase', url: 'https://wallet.coinbase.com/'}
    ]
}
const accountCenter: AccountCenterOptions = {
    desktop: {enabled: false},
    mobile: {enabled: false},
}
const web3Onboard = init({
    wallets,
    chains: getChainsForOnboard(),
    appMetadata,
    accountCenter,
})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(<Provider store={store}>
        <BrowserRouter>
            <Web3OnboardProvider web3Onboard={web3Onboard}>
                <App/>
            </Web3OnboardProvider>
        </BrowserRouter>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
