import React from 'react'
import {useConnectWallet} from '@web3-onboard/react'
import {Button} from '@mui/material'

const ConnectWalletButton = () => {
    const [{connecting}, connect] = useConnectWallet()

    const connectWallet = async () => {
        await connect()
    }

    return <Button
        variant="outlined" sx={{my: 1, mx: 1.5}}
        disabled={connecting}
        onClick={connectWallet}
    >
        {connecting ? 'Connecting...' : 'Connect wallet'}
    </Button>
}

export default ConnectWalletButton
