import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Signer} from 'ethers'
import {RootState} from './store'
//import Web3 from 'web3'
import {CHAINS} from '../utils/constants'
import {getFromStorage, setToStorage} from './storage'

interface AppState {
    currentNetwork: string | null
    loaded: boolean
    signer: Signer | null
    walletAddress: string | null
    web3: /*Web3 |*/ null
}

const initialState: AppState = {
    currentNetwork: null,
    loaded: false,
    signer: null,
    walletAddress: null,
    web3: null,
}

export const initialize = createAsyncThunk(
    'app/initialize',
    async (_, {dispatch}): Promise<boolean> => {
        dispatch(setWalletAddress(getFromStorage('walletAddress')))
        return true
    }
)
export const initializeNetwork = createAsyncThunk(
    'app/initializeNetwork',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork} = state.app

/*
        dispatch(setSelectedMintCollection(getFromNetworkStorage('selectedMintCollection', currentNetwork)))
        dispatch(setSelectedSbtCollection(getFromNetworkStorage('selectedSbtCollection', currentNetwork)))
*/
    }
)
export const networkChanged = createAsyncThunk(
    'app/networkChanged',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress} = state.app

/*
        dispatch(setNames({}))
        if (walletAddress) {
            dispatch(setMintCollections(null))
            dispatch(setSbtCollections(null))
            dispatch(setSelectedMintCollection(getFromNetworkStorage('selectedMintCollection', currentNetwork)))
            dispatch(setSelectedSbtCollection(getFromNetworkStorage('selectedSbtCollection', currentNetwork)))
        }
*/
    }
)
export const walletChanged = createAsyncThunk(
    'app/walletChanged',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress} = state.app

        if (!walletAddress) {
            return
        }

/*
        dispatch(setOnChainEvents(null))
*/
    }
)

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setCurrentNetwork: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                if (!!CHAINS[action.payload]) {
//                    state.web3 = new Web3(CHAINS[action.payload].rpcUrl)
                    state.currentNetwork = action.payload
                    return
                }
            }
            state.web3 = null
            state.currentNetwork = null
        },
        setSigner: (state, action: PayloadAction<Signer | null>) => {
            state.signer = action.payload
        },
        setWalletAddress: (state, action: PayloadAction<string | null>) => {
            state.walletAddress = action.payload
            setToStorage('walletAddress', action.payload)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initialize.fulfilled, (state, action: PayloadAction<boolean>) => {
            state.loaded = action.payload
        })
    },
})

export const getCurrentNetwork = (state: RootState): string | null => state.app.currentNetwork
export const getLoaded = (state: RootState): boolean => state.app.loaded
export const getSigner = (state: RootState): Signer | null => state.app.signer
export const getWalletAddress = (state: RootState): string | null => state.app.walletAddress
export const getWeb3 = (state: RootState): /*Web3 |*/ null => state.app.web3

export const {
    setCurrentNetwork,
    setSigner,
    setWalletAddress,
} = appSlice.actions

export default appSlice.reducer
