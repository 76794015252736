import React, {ReactNode, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useSearchParams} from 'react-router-dom'
import {
    Box,
    Container,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    Typography
} from '@mui/material'
import {AppDispatch} from '../store/store'
import {getAddresses, requestName} from '../store/ensSlice'
import {NULL_ADDRESS} from '../utils/constants'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LoopIcon from '@mui/icons-material/Loop'
import {compactString} from '../utils/functions'
import {CopyToClipboardBlock} from './elements'

const CheckName = () => {
    const [searchParams, setSearchParams] = useSearchParams('')
    const queryName = searchParams.get('name') || ''
    const [inputName, setInputName] = useState(queryName.toLowerCase())
    const ensAddresses = useSelector(getAddresses)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (inputName.length > 2) {
            dispatch(requestName(inputName))
        }
    }, [inputName])

    const onChangeHandler = (value: string) => {
        value = value.toLowerCase()
        setInputName(value)
        searchParams.set('name', value)
        setSearchParams(searchParams)
    }

    return <Container disableGutters maxWidth="sm" component="main" sx={{pt: 8, pb: 6}}>
        <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
        >
            Check Web3 name
        </Typography>
        <Box sx={{marginBottom: 5}}>
            <TextField
                label="Enter name"
                fullWidth={true}
                value={inputName}
                onChange={(e) => {
                    e.preventDefault()
                    onChangeHandler(e.target.value)
                }}
            />
        </Box>
        {inputName.length > 2 ?
            ensAddresses[inputName] ?
                <List dense sx={{width: '100%', bgcolor: 'background.paper'}}>
                    {ensAddresses[inputName].map((item, index) => {
                        const disabled = item.error || item.address !== NULL_ADDRESS
                        let msg: ReactNode = 'Loading...'
                        if (item.status) {
                            if (item.error) {
                                msg = item.error
                            } else if (item.address === NULL_ADDRESS) {
                                msg = 'Abailable'
                            } else if (item.address) {
                                msg = <>
                                    {compactString(item.address, 4)}
                                    {' '}
                                    <CopyToClipboardBlock
                                        text={item.address}
                                    />
                                </>
                            } else {
                                msg = 'Some error'
                            }
                        }
                        return <ListItem key={index}>
                                <ListItemAvatar>
                                    {!item.status ? <LoopIcon/> : disabled ? <CancelIcon color={'error'}/> :
                                        <CheckCircleIcon color={'success'}/>}
                                </ListItemAvatar>
                                <ListItemText >
                                    {item.name}.{item.tld} - {msg}
                                </ListItemText>
                        </ListItem>
                    })}
                    <Typography>
                        <CopyToClipboardBlock
                            buttonText={'Copy link to this page'}
                            text={window.location.href}
                        />
                    </Typography>
                </List>
                :
                <Typography>Loading...</Typography>
            :
            <Typography>Name must be greater 2 symbol</Typography>
        }
        <Typography sx={{mt: 3}}>
            Our service helps you find <strong>Web3-domains</strong> from a wide variety of providers and on a wide
            variety of networks/chains (EVM: Ethereum, BSC, Polygon, Arbitrum, etc.; Bitcoin; Zilliqa and many others).
        </Typography>
        <Typography>In MVP we added search by ENS, BNB and ARB names.</Typography>
        <Typography>Also we will add name purchase feature and other interesting features soon!</Typography>
    </Container>
}

export default CheckName
