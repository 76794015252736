import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {DefaultLayout} from './components/layouts'
import {About, NotFound} from './components/static'
import CheckName from './components/CheckName'

const App = () => {
    return <Routes>
        <Route path='/' element={<DefaultLayout/>}>
            <Route path='/' element={<CheckName/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='*' element={<NotFound/>}/>
        </Route>
    </Routes>
}

export default App
