import {ethers} from 'ethers'

export interface TldParams {
    contract: string
    provider: any
}

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY?.trim() || ''
export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000'
const providers = {
    arb: ethers.getDefaultProvider('https://arb1.arbitrum.io/rpc'),
    bnb: ethers.getDefaultProvider('https://bsc-dataseed.binance.org/'),
    eth: ethers.getDefaultProvider(`https://mainnet.infura.io/v3/${INFURA_KEY}`),
}
export interface ChainType {
    blockExplorer: string
    label: string
    rpcUrl: string
    token: string
}

export const Wallets: { [key: string]: string } = {
    Metamask: 'Metamask',
    WalletConnect: 'WalletConnect',
}
export const CHAINS: { [key: string]: ChainType } = {
    '0x1': {
        token: 'ETH',
        label: 'Ethereum',
        rpcUrl: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
        blockExplorer: 'https://etherscan.io',
    },
    '0x38': {
        token: 'BNB',
        label: 'BSC/BNB chain',
        rpcUrl: 'https://bsc-dataseed.binance.org/',
        blockExplorer: 'https://bscscan.com',
    },
    '0x89': {
        token: 'MATIC',
        label: 'Polygon',
        rpcUrl: 'https://polygon-rpc.com/',
        blockExplorer: 'https://polygonscan.com',
    },
    '0xa4b1': {
        token: 'ETH',
        label: 'Arbitrum',
        rpcUrl: 'https://arb1.arbitrum.io/rpc',
        blockExplorer: 'https://arbiscan.io',
    },
    '0xaa36a7': {
        token: 'ETH',
        label: 'Sepolia',
        rpcUrl: `https://sepolia.infura.io/v3/${INFURA_KEY}`,
        blockExplorer: 'https://sepolia.etherscan.io',
    },
}
export const TLDS: {[key: string]: TldParams} = {
    arb: {
         contract: '0x5d482D501b369F5bA034DEC5c5fb7A50d2D6Ca20',
         provider: providers.arb,
     },
    art: {
        contract: '0x828D6e836e586B53f1da3403FEda923AEd431019',
        provider: providers.eth,
    },
//    blast: {
//        contract: '',
//        provider: providers.arb,
//    },
    bnb: {
         contract: '0xE3b1D32e43Ce8d658368e2CBFF95D57Ef39Be8a6',
         provider: providers.bnb,
     },
//    'crypto': {
// //         contract: '',
// //         provider: ethers.getDefaultProvider(),
// //     },
    eth: {
        contract: '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85',
        provider: providers.eth,
    },
//    'inj': {
// //         contract: '',
// //         provider: ethers.getDefaultProvider(),
// //     },
//    'lens': {
// //         contract: '',
// //         provider: ethers.getDefaultProvider(),
// //     },
//    'sei': {
// //         contract: '',
// //         provider: ethers.getDefaultProvider(),
// //     },
}
export const APP_URL = process.env.REACT_APP_URL?.trim() || ''
export const WALLETCONNECT_ID = process.env.REACT_APP_WALLETCONNECT_ID?.trim() || ''
