import React from 'react'
import {Container, Typography} from '@mui/material'

const About = () => {
    return <Container disableGutters maxWidth="sm" component="main" sx={{pt: 8, pb: 6}}>
        <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
        >
            About
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
            Some text about service.
        </Typography>
    </Container>
}

export default About
