import React, {useEffect} from 'react'
import {useConnectWallet} from '@web3-onboard/react'
import {ConnectWalletButton, UserMenu} from './index'
import {getFromStorage} from '../../store/storage'

const WalletBlock = () => {
    const [{wallet}, connect] = useConnectWallet()

    useEffect(() => {
        const connectedWallet = getFromStorage('connectedWallet')
        if (connectedWallet && connectedWallet !== '') {
            connect({autoSelect: {label: connectedWallet, disableModals: true}})
        }
    }, [])

    return <>
        {wallet ? <UserMenu/> : <ConnectWalletButton/>}
    </>
}

export default WalletBlock
