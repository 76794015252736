import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ethers} from 'ethers'
import {RootState} from './store'
import {IENSAddresses, IENSName} from './types'
import {NULL_ADDRESS, TLDS} from '../utils/constants'
import EnsAbi from '../utils/abi/ens.json'

interface ENSState {
    addresses: IENSAddresses
}

const initialState: ENSState = {
    addresses: {},
}

export const requestName = createAsyncThunk(
    'ens/requestName',
    async (name: string, {dispatch}): Promise<void> => {
        const check = async (tld: string) => {
            dispatch(setAddress({tld, name, status: false}))
            let address = undefined
            let error = undefined
            try {
                const labelHash = ethers.keccak256(ethers.toUtf8Bytes(name))
                const tokenId = BigInt(labelHash)
                const contract = new ethers.Contract(
                    TLDS[tld].contract,
                    new ethers.Interface(JSON.stringify(EnsAbi)),
                    TLDS[tld].provider
                )
                if (await contract.available(tokenId)) {
                    address = NULL_ADDRESS
                } else {
                    address = await contract.ownerOf(tokenId)
                }
            } catch (e) {
                console.log(e)
                error = 'Some error, maybe wrong name'
            }
            dispatch(setAddress({tld, name, status: true, address, error}))
        }

        for (let key in TLDS) {
            check(key)
        }
    }
)

export const ensSlice = createSlice({
    name: 'ens',
    initialState,
    reducers: {
        setAddress: (state, action: PayloadAction<IENSName>) => {
            if (!state.addresses[action.payload.name]) {
                state.addresses[action.payload.name] = [action.payload]
            } else {
                let founded = false
                for (let key in state.addresses[action.payload.name]) {
                    const name = state.addresses[action.payload.name][key]
                    if (name.tld === action.payload.tld) {
                        state.addresses[action.payload.name][key] = action.payload
                        founded = true
                        break
                    }
                }
                if (!founded) {
                    state.addresses[action.payload.name].push(action.payload)
                }
            }
        },
        setAddresses: (state, action: PayloadAction<IENSAddresses>) => {
            state.addresses = action.payload
        },
    },
    /*
        extraReducers: (builder) => {
            builder.addCase(requestName.fulfilled, (state, action: PayloadAction<IENSName[] | null>) => {
                state.names = action.payload
            })
        },
    */
})

export const getAddresses = (state: RootState): IENSAddresses => state.ens.addresses

export const {
    setAddress,
    setAddresses,
} = ensSlice.actions

export default ensSlice.reducer
