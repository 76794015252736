import React from 'react'
import {useSelector} from 'react-redux'
import {useConnectWallet} from '@web3-onboard/react'
import {Button} from '@mui/material'
import {compactString} from '../../utils/functions'
import {getWalletAddress} from '../../store/appSlice'

const UserMenu = () => {
    const walletAddress = useSelector(getWalletAddress)
    const [{wallet}, connect, disconnect] = useConnectWallet()

    const disconnectWallet = async () => {
        if (wallet) {
            await disconnect(wallet)
        }
    }

    return <>
        {compactString(walletAddress || '')}
        <Button
            variant="outlined" sx={{my: 1, mx: 1.5}}
            onClick={disconnectWallet}
        >Disconnect</Button>
    </>
}

export default UserMenu
