import React, {useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Typography} from '@mui/material'

interface propsType {
    buttonText?: string
    text: string | number
}

const CopyToClipboardBlock = (props: propsType) => {
    const [copiedHint, setCopiedHint] = useState(false)

    return <CopyToClipboard
        text={props.text.toString()}
        onCopy={() => {
            setCopiedHint(true)
            setTimeout(() => {
                setCopiedHint(false)
            }, 5 * 1000)
        }}
    >
        {copiedHint ?
            <Typography variant={'body1'} sx={{color: 'secondary', display: 'inline'}}>Copied</Typography>
            :
            <button>{props.buttonText || 'Copy'}</button>
        }
    </CopyToClipboard>
}

export default CopyToClipboardBlock
